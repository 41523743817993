import * as React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

// ASSETS
import { SCREEN } from 'styles/screens'
import ChangingText from '../styled/changingTextAnimation'
import { getTranslation } from '../../data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1480px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: -20px;
    left: -20px;
    height: 80%;
    width: 50%;
    border: 3px solid var(--primary);
    z-index: -1;
  }
  &:after {
    content: '';
    position: absolute;
    right: -20px;
    bottom: -15px;
    height: 75%;
    width: 35%;
    background-color: var(--secondary);
    z-index: -1;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    &:before {
      top: -10px;
      left: -10px;
      border: 2px solid var(--primary);
    }
    &:after {
      right: -10px;
      bottom: -10px;
    }
  }
`

const HeroText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  flex-wrap: wrap;
  > h2 {
    text-align: right;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    display: flex;
    padding-left: 16px;
  }
`

const StyledH2 = styled.h2`
  margin: 0;
  user-select: none;
  color: transparent;
  -webkit-text-stroke: 2.7px var(--white);
  text-transform: uppercase;
  flex: 1 0 40%;
  text-align: right;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    font-size: 24px;
    -webkit-text-stroke: 1.3px var(--white);
  }
`

const AnimatedWrapper = styled.div`
  position: relative;
  margin-left: 20px;
  flex: 1 1 50%;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    flex-grow: 1;
    margin-left: 8px;
  }
  > div {
    @media only screen and (max-width: ${SCREEN.MOBILE}) {
      text-align: center;
      height: 24px;
      width: 100%;
    }
    //width: 563px;
    //text-align: left;
    height: 90px;
    h2 {
      margin: 0;
    }
  }
`

const HeroSection = () => (
  <Container>
    <FixedContainer>
      <ImageContainer>
        <StaticImage
          src="../../images/program_hero.jpeg"
          alt="workspace"
          layout="constrained"
          width={1400}
          height={580}
          placeholder="tracedSVG"
          objectPosition="top"
        />
        <HeroText>
          <StyledH2>{getTranslation('BUILDUP', 'BUILDUP')}</StyledH2>

          <AnimatedWrapper>
            <ChangingText>
              <StyledH2>
                {getTranslation('program_animation_line_1', 'Intelligence')}
              </StyledH2>
              <StyledH2>
                {getTranslation('program_animation_line_2', 'Knowledge')}
              </StyledH2>
              <StyledH2>
                {getTranslation('program_animation_line_3', 'Skills')}
              </StyledH2>
              <StyledH2>
                {getTranslation('program_animation_line_4', 'Experience')}
              </StyledH2>
              <StyledH2>
                {getTranslation('program_animation_line_5', 'Passion')}
              </StyledH2>
              <StyledH2>
                {getTranslation('program_animation_line_6', 'Life')}
              </StyledH2>
              <StyledH2>
                {getTranslation('program_animation_line_7', 'PROGRAM')}
              </StyledH2>
            </ChangingText>
          </AnimatedWrapper>
        </HeroText>
      </ImageContainer>
    </FixedContainer>
  </Container>
)

export default HeroSection
