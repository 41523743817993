import * as React from 'react'
import styled, { css, keyframes } from 'styled-components'

const fadeInOut = keyframes`
  0% {
    transform: translateY(-110%);
  }

  35% {
    transform: translateY(110%);
  }

  100% {
    transform: translateY(110%);
  }
`
const lastFrame = keyframes`
  0% {
    transform: translateY(-110%);
  }

  100% {
    transform: translateY(0);
  }
`
const Container = styled.div`
  overflow: hidden;
  position: relative;
`
const ItemWrapper = styled.div`
  margin: 0;
  position: absolute;
  transform: translateY(-110%);
  animation: ${p =>
    p.isLast
      ? css`
          ${lastFrame} 0.2s cubic-bezier(0, 1, 1, 1)
        `
      : css`
          ${fadeInOut} ${p.eachDuration}s cubic-bezier(0, 1, 1, 0)
        `};
  animation-delay: ${p => p.delayUnit + 0.5}s;
  animation-fill-mode: forwards;
`
const ChangingText = ({ children, containerCss, duration = 10.5 }) => {
  const count = React.Children.count(children)
  const eachDuration = duration / count
  const delayUnit = eachDuration / 3
  return (
    <Container css={containerCss}>
      {React.Children.map(children, (child, index) => (
        <ItemWrapper
          isLast={index === count - 1}
          count={count}
          eachDuration={eachDuration}
          delayUnit={delayUnit * index}
          key={index}
        >
          {child}
        </ItemWrapper>
      ))}
    </Container>
  )
}

export default React.memo(ChangingText)
