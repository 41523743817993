import * as React from 'react'
import styled from 'styled-components'

// COMPONENTS
import { ShadowText } from 'components/styled/typography'

import { SCREEN } from 'styles/screens'
import { getTranslation } from '../../data/dictionary'
import RenderHtml from '../shared/renderHtml'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  min-height: 800px;
  margin-top: 80px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    margin-top: 0px;
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1520px;
`

const DescriptionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-self: stretch;
  > div {
    flex: 0 0 45%;
  }
  h6 {
    font-weight: 400;
    line-height: 1.8;
    a  {
      font-size: 22px;
    }
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    margin-left: 0;
    flex-direction: column;
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    h6 {
      a  {
        font-size: 18px;
      }
    }
  }
`

const ProgramSection = () => (
  <Container>
    <FixedContainer>
      <ShadowText highlight>
        {/* <h3>{getTranslation('The Program', 'The Program')}</h3> */}
        <h3>Changing the mindset of a nation</h3>
      </ShadowText>
      <DescriptionContainer>
        <div>
          <h6>
            <RenderHtml html={getTranslation(
              'program_description_column_1',
              'The buildUp is an online program focused on selecting amateur\n' +
              '            specialists (and engineers and marketing) into the specific programs\n' +
              '            of interest and making them high-level <a href="#">Test Link</a> professionals in 4 months\n' +
              '            with effective skills and practical knowledge.', false)}/>
          </h6>
        </div>
        <div>
          <h6>
            <RenderHtml html={getTranslation(
              'program_description_column_2',
              'Every immersive program is an advanced online residency, designed to\n' +
              '            help individuals launch a meaningful and successful career! Our\n' +
              '            focus will be individuals with some working background, but also we\n' +
              '            can support career changers, coming from non-traditional backgrounds\n' +
              '            (i.e. banking, accountants, musicians). Our programs will teach an\n' +
              '            immense amount of material, covering fundamental concepts and modern\n' +
              '            technologies all done online with international tutors.', false)}/>
          </h6>
        </div>
      </DescriptionContainer>
    </FixedContainer>
  </Container>
)

export default ProgramSection
