import * as React from 'react'
import styled from 'styled-components'
import { Card } from 'components/styled/card'
import ArrowIcon from 'svgs/arrow_top_right.svg'
import { SCREEN } from 'styles/screens'
import { ShadowText } from 'components/styled/typography'
import { getTranslation } from 'data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  .white-badge {
    background-color: white;
    border-radius: 100px;
    border: 1px solid #221F1F;
    padding: 0 15px;
    margin-top: 7px !important;
    width: fit-content;

  }
  .title {
    padding-top: 0 !important;
    margin-top: 0 !important;
    font-size: 22px;
    font-weight: 400;
    text-align: left;
  }
  .mb-0 {
    margin-bottom: 0;
  }
  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      align-items: start;
      font-size: 20px;
      margin-top: 26px;

      > div {
        margin-right: 1rem;
        margin-top: 11px;

        > svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
    ul li {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    > ul li {
      > div {
        margin-left: 1rem;
      }
    }
  }
`

const FixedContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1480px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  // margin-left: auto;
  // margin-right: auto;
`

const CardColumn = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    padding-top: 15px;
    padding-bottom: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const StyledCard = styled(Card)`
  position: relative;
  padding: 0;
  height: 100%;
  div.card-header {
    background-color: #ecfb70;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding: 40px 50px 9px 70px;
  }
  div.card-body {
    padding: 20px 48px 9px 48px;
  }
  width: 100%;
  max-width: 1000px;
  align-self: stretch;
  margin-bottom: 20px;
  h5 {
    font-weight: 600;
  }
  h6 {
    font-weight: 500;
  }
`

class ValueTimeCommitment extends React.Component {
  render() {
    return (
      <Container className={this.props.type}>
        <FixedContainer>
          <ShadowText highlight>
            <h3>{getTranslation("value-requires-time-commitment", "", true)}</h3>
          </ShadowText>
          <div>
            <h4 className="py-40px title">
              {
                getTranslation("scedule-description", "", true)
              }
            </h4>
            <Row>
              <CardColumn>
                <StyledCard>
                  <div className="card-header">
                    <h5 className="mt-0 mb-0">{getTranslation("individual-education", "", true)}</h5>
                    <h6 className="mt-0 white-badge">{getTranslation("4-hours", "", true)}</h6>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li className="mt-5px">
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                        {
                          getTranslation("individual-education-text-1", "", true)
                        }
                      </li>

                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                       {
                        getTranslation("individual-education-text-2", "", true)
                       }
                      </li>
                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                        {
                          getTranslation("individual-education-text-3", "", true)
                        }
                      </li>
                    </ul>
                  </div>
                </StyledCard>
              </CardColumn>
              <CardColumn>
                <StyledCard>
                  <div className="card-header">
                    <h5 className="mt-0 mb-0">{getTranslation('individual-work', '', true)}</h5>
                    <h6 className="mt-0 white-badge">{getTranslation("4-hours", "", true)}</h6>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                          {
                            getTranslation("individual-work-text-1", "", true)
                          }
                      </li>

                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                        {
                          getTranslation("individual-work-text-2", '', true)
                        }
                      </li>
                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                        {
                          getTranslation("individual-work-text-3", "", true)
                        }
                      </li>
                    </ul>
                  </div>
                </StyledCard>
              </CardColumn>
              <CardColumn>
                <StyledCard>
                  <div className="card-header">
                    <h5 className="mt-0 mb-0">{getTranslation("teamwork", '', true)}</h5>
                    <h6 className="mt-0 white-badge">{getTranslation("4-hours", '', true)}</h6>
                  </div>
                  <div className="card-body">
                    <ul>
                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                        {
                          getTranslation("teamwork-text-1", "", true)
                        }
                      </li>

                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                        {
                          getTranslation("teamwork-text-2", "", true)
                        }
                      </li>
                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                        {getTranslation("teamwork-text-3", "", true)}
                      </li>
                      <li>
                        <div className="d-flex">
                          <ArrowIcon />
                        </div>
                        {
                          getTranslation("teamwork-text-4", "", true)
                        }
                      </li>
                    </ul>
                  </div>
                </StyledCard>
              </CardColumn>
            </Row>
          </div>
        </FixedContainer>
      </Container>
    )
  }
}

export default ValueTimeCommitment
