import * as React from 'react'
import styled from 'styled-components'
import { SCREEN } from 'styles/screens'
import { ShadowText } from 'components/styled/typography'
import Calendar from 'components/home/calendar'
import { getTranslation } from 'data/dictionary'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 100px 40px 100px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-3rem {
    padding-top: 3rem;
  }

  .pb-100px {
    padding-bottom: 100px;
  }

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    .pb-100px {
      padding-bottom: 30px;
    }

    .pt-3rem {
      padding-top: 1.5rem;
    }
  }
`

const FixedContainer = styled.div`
  width: 100% !important;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 1480px;
  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
`

const Column = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 1 0 0%;

  @media only screen and (max-width: ${SCREEN.NOTEBOOK}) {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`

const LetsTalk = () => {
  return (
    <div>
      <Container className="container">
        <FixedContainer>
          <ShadowText highlight>
            <h3>{getTranslation("lets-talk", "", true)}</h3>
          </ShadowText>
          <Row>
            {/* <DescriptionContainer> */}
            <Column className="pr-0 pl-0">
              <h6>
                {
                  getTranslation("lets-talk-description", "", true)
                }
              </h6>
            </Column>
          </Row>
          <Calendar />
        </FixedContainer>
      </Container>
    </div>
  )
}

export default LetsTalk
